
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import { RequestPaging } from '@/utils/util'
import { apiRegionAgentList, apiRegionAgentTypeList, apiRegionAgentUpdate } from '@/api/finance'
import LsDialog from '@/components/ls-dialog.vue'
import Commission from './components/commission.vue'
import DatePicker from '@/components/date-picker.vue'

@Component({
  components: {
    DatePicker,
    lsPagination,
    LsDialog,
    Commission
  }
})
export default class RegionAgentList extends Vue {
  /** S Data **/
  pager: RequestPaging = new RequestPaging()
  pickerOptions = {
    shortcuts: [
      {
        text: '最近一周',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近一个月',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近三个月',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          picker.$emit('pick', [start, end])
        }
      }
    ]
  }

  commission: any = {
    mobile: ''
  }

  form = {
    mobile: '',
    type: '',
    status: '',
    area_name: '',
    note: '',
    create_start_time: 0,
    create_end_time: 0
  }

  otherLists: any = {
    type_lists: []
  };

  extendInfo: any = {
    agent_money: '',
    agent_withdrawal_money: ''
  }

  // 日期选择器数据
  timeForm = []
  /** E Data **/

  /** S Methods **/

  showCommission (mobile: any) {
    this.commission.mobile = mobile
  }

  handleAgent (row: any, type = 0) {
    apiRegionAgentUpdate({ id: row.id, type: type }).then(res => {
      this.getListInit()
    })
  }

  // 拆分日期选择器时间
  splitTime () {
    if (this.timeForm != null) {
      this.form.create_start_time = new Date(this.timeForm[0]).getTime() / 1000
      this.form.create_end_time = new Date(this.timeForm[1]).getTime() / 1000
    } else {
      this.form.create_start_time = 0
      this.form.create_end_time = 0
    }
  }

  onReset () {
    this.form = {
      mobile: '',
      type: '',
      status: '',
      area_name: '',
      note: '',
      create_start_time: 0,
      create_end_time: 0
    }
    this.timeForm = []
    this.pager.page = 1
    this.getListInit()
  }

  query () {
    this.pager.page = 1
    this.getListInit()
  }

  // 获取数据列表
  getListInit (): void {
    this.pager.request({
      callback: apiRegionAgentList,
      params: {
        ...this.form
      }
    }).then(res => {
      this.extendInfo = res.extend
    })
  }

  getRegionAgentTypeList () {
    apiRegionAgentTypeList().then(res => {
      this.otherLists = res
    })
  }

  /** E Methods **/
  /** S ods **/
  created () {
    this.getRegionAgentTypeList()
    this.getListInit()
  }
}
