
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import { apiRegionAgentCommissionList } from '@/api/finance'
import lsPagination from '@/components/ls-pagination.vue'
import DatePicker from '@/components/date-picker.vue'

@Component({
  components: { DatePicker, lsPagination, LsDialog }
})
export default class RegionAgentCommissionDialog extends Vue {
  pager: RequestPaging = new RequestPaging()

  visible = {
    val: false
  }

  form = {
    mobile: '',
    sn: '',
    create_start_time: '',
    create_end_time: ''
  }

  @Prop({ default: '' }) mobile!: string | number // 手机号码

  @Watch('mobile', { immediate: true })

  handleMobile (value: string) {
    console.log(value)
    if (value) {
      this.form = {
        mobile: value,
        sn: '',
        create_start_time: '',
        create_end_time: ''
      }
      this.getListInit()
      this.visible.val = true
    }
  }

  onSearch () {
    this.pager.page = 1
    this.getListInit()
  }

  onReset () {
    this.form.sn = ''
    this.form.create_start_time = ''
    this.form.create_end_time = ''
    this.pager.page = 1
    this.getListInit()
  }

  getListInit (): void {
    this.pager.request({
      callback: apiRegionAgentCommissionList,
      params: {
        ...this.form
      }
    })
  }

  handleClose () {
    this.visible.val = false
    this.$emit('update:mobile', '')
  }
}

